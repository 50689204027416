import * as React from "react"
import { graphql } from "gatsby"
import * as transformer from "../gatsby/transformers/productTaxonomyTransformer"
import ProductTaxonomyPage from "../social-supermarket/pages/product-taxonomy/ProductTaxonomyPage"
import { filterByVisibility } from "../social-supermarket/util/productUtil"

const GiftGuideTemplate = ({
  data: { wpGiftGuide, allWpImpact },
  pageContext: { products, queryId },
}) => {
  wpGiftGuide.impacts = allWpImpact
  const taxonomy = transformer.fromGatsbyNoProducts(wpGiftGuide, "gift_guide")
  taxonomy.products = filterByVisibility(products, "Product Page")

  return (
    <ProductTaxonomyPage
      taxonomy={taxonomy}
      collectionName={`Gift Guide: ${taxonomy.name}`}
      queryId={queryId}
    />
  )
}

export default GiftGuideTemplate

export const query = graphql`
  query ($id: String) {
    allWpImpact {
      nodes {
        id
        slug
        name
      }
    }

    wpGiftGuide(id: { eq: $id }) {
      id
      slug
      name
      description
      custom {
        categoryDescription
      }
    }
  }
`
